import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import bb_bucket_image from "../../Asset/bb_bucket_order_confirm.png";
import Bg from "../../Asset/Bg.png";
import Location from "../../Asset/location.png";
import bottom_bg from "../../Asset/order_confirm_bottom_bg.png";
import IconConstants from "../../utils/IconConstants";

const OrderConfirm = ({ address }) => {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://wa.me/918884142400";
    }, 2000)
  }, [])

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${Bg})`,
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 1,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ height: "12.42vh", width: "100%" }}>
            <img
              src={
                "https://ysquare-order-management.s3.ap-south-1.amazonaws.com/Bucket+Biryani/Support+Images/Bucket+Biryani+Banner.png"
              }
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "90%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-around",
            gap: "0.5vw",
            mt: "0.87vh",
          }}
        >
          <Box sx={{ display: "flex", }}>
            <Box>
              <Box sx={{ width: "4.872vw", height: "4.872vw" }}>
                <img
                  src={Location}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "3.077vw",
                  fontWeight: 600,
                }}
              >
                {address}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height:"66%"
      }}>

        <Box sx={{ width: "45.867vw", height: "41.067vw" }}>
          <img
            src={IconConstants.Tick}
            alt="Success Tick Icon"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            mt: "8%",
            color: "#EBA059",
          }}
        >
          <Typography
            sx={{
              fontSize: "6.667vw",
              fontWeight: 700,
            }}
          >
            <>Thank You!<br />Order Completed</>
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: "rgba(254, 254, 254, 1)",
            maxWidth: "38.462vw",
            maxHeight: "38.462vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            padding: "4.1vw",
            position: "absolute",
            top: "calc(100vh - 18.63vh - 19.231vw)",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 2,
          }}
        >
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "34.359vw",
            height: "34.359vw",
            borderRadius: "50%",
            border: "1px dashed rgba(255, 203, 215, 1)",
          }}>
            <img
              src={bb_bucket_image}
              alt="Bucket Image"
              style={{
                width: "24.872vw",
                height: "15.418vh",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: "18.63vh",
            backgroundImage: `url(${bottom_bg})`,
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>

  );
};

export default OrderConfirm;
