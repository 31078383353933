import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Button, Card, CardMedia, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Bg from "../../Asset/bg_outlet.png";
import OutletLogo from "../../Asset/outlet_logo.png";
import timeOutGif from "../../Asset/timeout.gif";
import IconConstants from "../../utils/IconConstants";

const ManageOutlet = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (name) => {
    return new URLSearchParams(location.search).get(name);
  };

  const manager_key = getQueryParam("manager_key");
  console.log("manager_key is >>>>", manager_key);

  const [loader, setLoader] = useState(true);
  const [managerDetails, setManagerDetails] = useState({});
  const [outletDetails, setOutletDetails] = useState([]);
  const [managerValid, setmanagerValid] = useState("");

  useEffect(() => {
    if (manager_key) {
      fetchManagerDetails(manager_key);
    }
  }, [manager_key]);

  const fetchManagerDetails = async (manager_key) => {
    const response = await axios.get(
      `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/manager/getManagerDetails/${manager_key}`
    );
    setManagerDetails(response?.data?.data);

    sessionStorage.setItem("token", response?.data?.data?.manager_token);

    fetchOutletsForManager(manager_key);
  };

  const fetchOutletsForManager = async (manager_key) => {
    try {
      const response = await axios.get(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/outlet/getManagerOutlets/${manager_key}`,
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );

      setOutletDetails(response?.data?.data || []);

      setmanagerValid(response?.data?.statusCode === 200 ? true : false);
      setLoader(false);
    } catch (error) {
      console.log("ERROR:", error)
      setLoader(false)
    }
  };

  const handleCardClick = (outlet) => {
    navigate(`/manager/outlet?outlet_key=${outlet.outlet_key}`);
  };

  return (
    <Box
      sx={{
        width: 1,
        height: "100vh",
        pt: "4%",
        backgroundImage: `url(${IconConstants.OutletBg})`,
        backgroundSize: "cover",
        overflow: "hidden",
      }}
    >
      {
        loader ? (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) :
        !managerValid ? (
          <Box
            sx={{
              backgroundImage: `url(${Bg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: 1,
                minHeight: "6.5vh",
              }}
            >
              <Box sx={{ width: "45%" }}>
                <Box sx={{ width: "41.795vw", height: "7.436vw" }}>
                  <img
                    src={
                      "https://ysquare-order-management.s3.ap-south-1.amazonaws.com/Bucket+Biryani/Logo/bucket_biryani_logopng.png"
                    }
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 100, height: 100 }}
                  image={timeOutGif}
                  alt="Time Out"
                />
                <Typography align="center" fontWeight="bold">
                  Session Expired
                </Typography>

                <Button
                  href="https://wa.me/918884142400"
                  sx={{ mt: "4vh", background: "#28ad64", color: "white" }}
                >
                  <Typography
                    sx={{ fontSize: "4vw", mx: "2vw", textTransform: "none" }}
                  >
                    Login to start
                  </Typography>
                  <WhatsAppIcon />
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: 1,
                minHeight: "6.5vh",
              }}
            >
              <Box sx={{ width: "45%" }}>
                <Box sx={{ width: "41.795vw", height: "7.436vw" }}>
                  <img
                    src={
                      "https://ysquare-order-management.s3.ap-south-1.amazonaws.com/Bucket+Biryani/Logo/bucket_biryani_logopng.png"
                    }
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{ margin: "0 auto 0 auto", width: "80.256%" }}>
              <Typography
                align="center"
                sx={{
                  margin: "3.1% 0 2.7% 0",
                  fontSize: "4.103vw",
                  fontWeight: 800,
                }}
              >
                Manage Outlet
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3.84vw",
                  width: 1,

                  height: "78vh",
                  maxHeight: "78vh",
                  overflowY: "auto",
                }}
              >
                {outletDetails.map((outlet) => (
                  <Card
                    sx={{
                      minHeight: "fit-content",
                      display: "flex",
                      borderRadius: "5px",
                      padding: "3%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <Box sx={{ height: "20.513vw", width: "20.513vw" }}>
                        <img
                          src={OutletLogo}
                          alt=""
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Box>
                    </Box>

                    <Box sx={{ width: "70%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1.2vw",
                        }}
                      >
                        <Typography sx={{ fontSize: "3.59vw", fontWeight: 700 }}>
                          {outlet.name}
                        </Typography>
                        <Typography sx={{ fontSize: "2.051vw" }}>Location</Typography>
                        <Typography sx={{ fontSize: "2.564vw" }}>
                          {outlet.address}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          mt: "3%",
                        }}
                      >
                        <Button
                          onClick={(e) => {
                            handleCardClick(outlet);
                          }}
                          sx={{
                            width: "28.718vw",
                            height: "8.974vw",
                            backgroundColor: "#F7E8EE",
                            textTransform: "none",
                            boxShadow: "0px 4px 4px 0px #00000040",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "3.077vw",
                              color: "#FF9012",
                              fontWeight: 800,
                            }}
                          >
                            Food Updates
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                ))}
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  );
};

export default ManageOutlet;
