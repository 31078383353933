import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Typography
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconConstants from "../../utils/IconConstants";

const ManagerMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParam = (name) => {
    return new URLSearchParams(location.search).get(name);
  };

  const outlet_key = getQueryParam("outlet_key");
  console.log("outlet is >>>>", outlet_key);

  const [toast, setToast] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [menuItems, setMenuItems] = useState([]);
  const [outletStockItems, setOutletStockItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [outletModel, setOutletModel] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [updateButton, setUpdateButton] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    fetchOutletDetails(outlet_key);
    fetchOutletMenu(outlet_key);
    fetchMenuItems();
  }, [outlet_key]);

  useEffect(() => {
    settingDisplayItems();
  }, [menuItems, outletStockItems]);

  useEffect(() => {
    console.log("DISPLAY ITEMS :::::::::::::", displayItems);
  }, [displayItems]);

  const handleToastClose = () => {
    setToast({ ...toast, open: false });
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/menu/getMenuItems`,
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );
      const menuItems = response?.data?.data?.map((item) => {
        return {
          id: item.menu_id,
          name: item.name,
          price: item.price,
          description: item.description,
          imageUrl: item.image,
          quantity: 0,
        };
      });
      setMenuItems(menuItems);
    } catch (error) {
      console.error("Error fetching outlet stock:", error);
    }
  };

  const fetchOutletDetails = async (outlet_key) => {
    try {
      const response = await axios.get(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/outlet/getOutletDetails/${outlet_key}`,
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );
      console.log("RESPONSE =====> ", response?.data?.data[0]);
      setOutletModel(response?.data?.data[0]);
    } catch (error) {
      console.log("ERROR OCCURED WHILE FETCHING THE OUTLET DETAIL");
    }
  };

  const fetchOutletMenu = async (outletKey) => {
    try {
      const response = await axios.get(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/outlet/getoutletstockbykey/${outletKey}`,
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );
      const foodItems = response.data.data;
      const outletStockItems_res = foodItems.map((item) => {
        return {
          id: item.menu_id,
          name: item.name,
          price: item.price,
          description: item.description,
          imageUrl: item.image,
          quantity: 0,
          is_available: item.is_available,
        };
      });
      setOutletStockItems(outletStockItems_res);
      console.log("🚀 ~ fetchOutletStock ~ response:", response);
    } catch (error) {
      console.error("Error fetching outlet stock:", error);
    }
  };

  const settingDisplayItems = () => {
    console.log("MENU ITEMS ::::::>>>>>>>>>", menuItems);

    try {
      const combinedItems = menuItems.map((item) => {
        if (outletStockItems.length > 0) {
          const matchingStockItem = outletStockItems.find(
            (stockItem) => stockItem.id === item.id
          );

          console.log("MATCHING ITEM FOUND IS :::::>>>>>>", matchingStockItem);

          return {
            ...item,
            available: matchingStockItem?.is_available
              ? matchingStockItem.is_available
              : false,
          };
        } else {
          return {
            ...item,
            available: false,
          };
        }
      });

      console.log("COMBINED ITEMS ::::>>>>", combinedItems);

      setDisplayItems(combinedItems);
    } catch (error) {
      console.error("ERROR SETTING DISPLAY ITEMS", error);
    }
  };

  const handleSwitchChange = (item) => {
    setDisplayItems((prevItems) => {
      return prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? { ...prevItem, available: !item.available }
          : prevItem
      );
    });
  };

  const handleOutletStock = async () => {
    setUpdateButton(true);
    try {
      const outletStockObj = {
        outlet_key: outlet_key,
        menu_items: displayItems,
      };

      const response = await axios.post(
        "https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/outletStock/updateOutletStock",
        outletStockObj,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        handleClose();
        window.location.replace(`/manager/outlet/confirm`);
      } else {
        setToast({
          open: true,
          severity: "error",
          message: "Failed to update outlet menu. Please try again.",
        });
      }
    } catch (error) {
      console.log("ERROR OCCURED IN UPDATE OUTLET STOCK");
    } finally {
      setUpdateButton(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${IconConstants.OutletBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          pt: "4%",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "#fdfeff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: 1,
          }}
        >
          <Box sx={{ width: "45%" }}>
            <Box sx={{ width: "41.795vw", height: "7.436vw" }}>
              <img
                src={
                  "https://ysquare-order-management.s3.ap-south-1.amazonaws.com/Bucket+Biryani/Logo/bucket_biryani_logopng.png"
                }
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "51%",
              display: "flex",
              alignItems: "flex-start",
              gap: "0.5vw",
            }}
          >
            <Box>
              <Box sx={{ width: "4.872vw" }}>
                <img
                  src={IconConstants.Location}
                  style={{ width: "100%" }}
                  alt=""
                />
              </Box>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "3.077vw", fontWeight: 600 }}>
                {outletModel?.address}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ margin: "0 auto 0 auto", width: "91.795%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)}>
              <Box sx={{ height: "5.328vw", width: "5.328vw" }}>
                <img src={IconConstants.Back} alt="" style={{ width: "100%", height: "100%" }} />
              </Box>
            </IconButton>
            <Typography
              sx={{
                margin: "3.1% 0 2.7% 0",
                fontSize: "4.103vw",
                fontWeight: 800,
              }}
            >
              Manage Outlet
            </Typography>
          </Box>

          <Box
            sx={{
              height: "75vh",
              maxHeight: "75vh",
              overflowY: "auto",
            }}
          >
            <Grid container spacing={""}>
              {displayItems.map((item, ind) => (
                <Grid item xs={12} key={item.id} sx={{ width: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      boxShadow:
                        "12.48px 27.04px 52px 0px rgba(245, 246, 254, 0.1)",
                      borderRadius: "7.28px",
                      minHeight: "27.467vw",
                      padding: "5% 2.5%",
                      backgroundColor: "#FFFFFF",
                      mb: "4%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        width: 1,
                      }}
                    >
                      <Box sx={{ width: "19%" }}>
                        <Box
                          sx={{
                            height: "16.533vw",
                            width: "16.533vw",
                            position: "relative",
                          }}
                        >
                          <img
                            src={item.imageUrl}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "7.28px",
                            }}
                          />
                          {!item.available ? (
                            <Box
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                borderRadius: "7.28px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>

                      <Box sx={{ width: "77%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              width: "80%",
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "1.5vw",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "3.333vw", fontWeight: 500 }}
                            >
                              {item?.name?.split("(")?.[0]}
                            </Typography>
                            {!!item?.name?.split("(")?.[1] ? (
                              <Typography
                                sx={{
                                  fontSize: "2.564vw",
                                  color: "#989898",
                                  fontWeight: 500,
                                }}
                              >
                                {`(${item?.name?.split("(")?.[1]}`}
                              </Typography>
                            ) : (
                              ""
                            )}

                            <Typography
                              sx={{
                                color: item.available ? "#EBA059" : "#a3a3a3",
                                fontSize: "4.103vw",
                                fontWeight: 800,
                              }}
                            >
                              {"Rs " + item.price}
                            </Typography>
                          </Box>

                          {/* Switch */}

                          <Box sx={{ width: "20%", mt: "5%" }}>
                            <Checkbox
                              icon={
                                <Box sx={{ height: "7.5vw", width: "7.5vw" }}>
                                  <img
                                    src={IconConstants.UnChecked}
                                    alt=""
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </Box>
                              }
                              checkedIcon={
                                <Box sx={{ height: "7.5vw", width: "7.5vw" }}>
                                  <img
                                    src={IconConstants.Checked}
                                    alt=""
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </Box>
                              }
                              size="small"
                              checked={item.available}
                              onChange={(e) => handleSwitchChange(item)}
                            />
                          </Box>
                        </Box>

                        <Typography
                          sx={{
                            fontSize: "2.308vw",
                            color: "#989898",
                            fontWeight: 500,
                            mt: "2%",
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            m: "4% auto 0 auto",
            textAlign: "center",
          }}
          onClick={handleOpen}
        >
          <Button
            sx={{
              position: "fixed",
              bottom: "1%",
              left: "50%",
              transform: "translate(-50%)",
              right: "0",
              height: "12.051vw",
              width: "90%",
              borderRadius: "1vw",
              backgroundColor: "#F7E8EE",
              boxShadow: "1.95px 1.95px 2.6px 0px #00000026",
              textTransform: "none",
            }}
          >
            <Typography
              sx={{
                color: "#EBA059",
                fontSize: "4.103vw",
                fontWeight: 800,
              }}
            >
              Update
            </Typography>
          </Button>
        </Box>
      </Box>

      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            maxWidth: "85vw",
            width: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: "5%",
            outline: "none",
            border: "none",
            borderRadius: "7.28px",
            backgroundImage: `url(${IconConstants.ConfirmationBg})`,
            backgroundSize: "cover",
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "4.2vw" }}>
            Unavailable at the moment:
          </Typography>
          <Typography sx={{ fontSize: "3.733vw" }}>
            {displayItems
              .filter((item) => !item.available)
              .map((item, index) => (
                <li key={index} style={{ margin: "1vw" }}>
                  {item.name.split("(")?.[0]}
                </li>
              ))}
          </Typography>
          <Button
            disabled={updateButton}
            variant="contained"
            onClick={handleOutletStock}
            sx={{
              mt: "2.367vh",
              width: "100%",
              height: "5.908vh",
              background: "rgba(238, 238, 238, 1)",
              color: "rgba(170, 18, 52, 1)",
              fontSize: "4vw",
              fontWeight: 700,
              textTransform: "none",
            }}
          >
            Update Food Status
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={toast.open}
        autoHideDuration={4000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleToastClose}
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManagerMenu;
