import { useRoutes } from "react-router-dom";
import ManagerMenu from "../pages/menu/ManagerMenu";
import { Menu } from "../pages/menu/Menu";
import OrderConfirm from "../pages/order/OrderConfirm";
import ManageOutlet from "../pages/outlet/ManageOutlet";
import OutletConfirm from "../pages/outlet/OutletConfirm";


function BaseRoutes() {

    let routes = useRoutes([
        {
            path: "/menu",
            children: [
                {
                    path: "outlet",
                    element:
                        <Menu />
                }
            ]
        },
        {
            path: "/order",
            children: [
                {
                    path: "confirm",
                    element:
                        <OrderConfirm />
                }
            ]
        },
        {
            path: "/manager",
            children: [
                {
                    path: "",
                    element: <ManageOutlet />,
                },
                {
                    path: "outlet",
                    children: [
                        {
                            path: "",
                            element: <ManagerMenu />
                        },
                        {
                            path: "confirm",
                            element:
                                <OutletConfirm />
                        }
                    ]
                }
            ]
        }
    ])

    return routes;

}

export default BaseRoutes;
