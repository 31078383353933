import OutletBg from "../Asset/outlet_bg.png";
import Location from "../Asset/location.png";
import Checked from "../Asset/checked.svg";
import UnChecked from "../Asset/unchecked.svg";
import ConfirmationBg from "../Asset/confirm_bg.png";
import Plus from "../Asset/plus.svg";
import Minus from "../Asset/minus.svg";
import Cart from "../Asset/cart.svg";
import Sync from "../Asset/sync.svg";
import Tick from "../Asset/tick.png";
import Back from "../Asset/back.svg";
import Logo from "../Asset/bucket_logo.svg";


let IconConstants = {
    Logo,
    OutletBg,
    Location,
    Checked,
    UnChecked,
    ConfirmationBg,
    Plus,
    Minus,
    Cart,
    Sync,
    Tick,
    Back
}


export default IconConstants;
