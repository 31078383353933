import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { RemoveRounded } from "@mui/icons-material";
import IconConstants from "../utils/IconConstants";

const foodItems = [
  {
    id: 1,
    name: "Cheeseburger",
    price: "$5.99",
    description: "A delicious cheeseburger with lettuce and tomato.",
    imageUrl: "https://via.placeholder.com/150",
    quantity: 0,
  },
  {
    id: 2,
    name: "Pepperoni Pizza",
    price: "$8.99",
    description: "Classic pepperoni pizza with mozzarella cheese.",
    imageUrl: "https://via.placeholder.com/150",
    quantity: 0,
  },
  {
    id: 3,
    name: "Biriyani",
    price: "$5.99",
    description: "A delicious cheeseburger with lettuce and tomato.",
    imageUrl: "https://via.placeholder.com/150",
    quantity: 0,
  },
  {
    id: 4,
    name: "Pepsi",
    price: "$8.99",
    description: "Classic pepperoni pizza with mozzarella cheese.",
    imageUrl: "https://via.placeholder.com/150",
    quantity: 0,
  },
  // Add more items as needed
];

const MenuList = ({ foodItems, onCartUpdate }) => {
  console.log("🚀 ~ MenuList ~ foodItems:", foodItems);
  // const menuItems = foodItems.map((item) => { return { id: item.menu_id, name: item.name, price: item.price, description: item.description, imageUrl: item.image, quantity: 0 } })
  // console.log("🚀 ~ MenuList ~ menuItems:", menuItems)
  const [cartItems, setCartItems] = useState(foodItems);
  console.log("🚀 ~ MenuList ~ cartItems:", cartItems);
  console.log("🚀 ~ MenuList ~ FoodItems to display in menu page:", foodItems);

  useEffect(() => {
    if (foodItems.length) {
      setCartItems(foodItems);
    }
  }, [foodItems.length]);

  useEffect(() => {
    setCartItems(foodItems);
  }, [foodItems]);

  const handleAddToCart = (item) => {
    setCartItems((prevCartItems) => {
      const existingItem = prevCartItems.find(
        (cartItem) => cartItem.id === item.id
      );

      let newCartItems;
      if (existingItem) {
        newCartItems = prevCartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        newCartItems = [...prevCartItems, { ...item, quantity: 1 }];
      }

      onCartUpdate(newCartItems);
      return newCartItems;
    });
  };

  const handleRemoveFromCart = (item) => {
    setCartItems((prevCartItems) => {
      const existingItem = prevCartItems.find(
        (cartItem) => cartItem.id === item.id
      );

      let newCartItems;
      if (existingItem && existingItem.quantity > 0) {
        newCartItems = prevCartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: Math.max(cartItem.quantity - 1, 0) }
            : cartItem
        );
      } else {
        return prevCartItems;
      }

      onCartUpdate(newCartItems);
      return newCartItems;
    });
  };

  return (
    <Box
      sx={{
        width: 1,
        height: "72vh",
        maxHeight: "72vh",
        overflowY: "scroll",
        // boxShadow: "0px 3px 8px 0px #0000003D"
      }}
    >
      <Grid container rowGap={"2.5vw"}>
        {cartItems
          .map((item, ind) =>
            item.available ? (
              <Grid item xs={12} key={item.id} sx={{ width: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // boxShadow:
                    //   "12.48px 27.04px 52px 0px rgba(245, 246, 0.1)",
                    borderRadius: "7.28px",
                    minHeight: "20vw",
                    padding: "5% 2.5%",
                    // backgroundColor: ind === 0 ? "#FFFFFF" : "inherit",
                    backgroundColor: "#FFFFFF"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ width: "19%" }}>
                      <Box sx={{ height: "16.533vw", width: "16.533vw" }}>
                        <img
                          src={item.imageUrl}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "7.28px",
                          }}
                        />
                      </Box>
                    </Box>

                    <Box sx={{ width: "77.5%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "1.5vw",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "3.333vw", fontWeight: 500 }}
                          >
                            {item?.name?.split("(")?.[0]}
                          </Typography>

                          {!!item?.name?.split("(")?.[1] ? (
                            <Typography
                              sx={{
                                fontSize: "2.564vw",
                                color: "#989898",
                                fontWeight: 500,
                              }}
                            >
                              {`(${item?.name?.split("(")?.[1]}`}
                            </Typography>
                          ) : (
                            ""
                          )}

                          <Typography
                            sx={{
                              color: "#EBA059",
                              fontSize: "4.103vw",
                              fontWeight: 800,
                            }}
                          >
                            {"Rs " + item.price}
                          </Typography>
                        </Box>

                        {/* {item.quantity > 0 ? ( */}
                        <Box sx={{ width: "33%", mt: "6.5%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <IconButton
                              aria-label="remove from cart"
                              onClick={() => handleRemoveFromCart(item)}
                              size="small"
                              sx={{
                                p: 0,
                              }}
                            >
                              <Box
                                sx={{
                                  width: "6.933vw",
                                  height: "6.933vw",
                                }}
                              >
                                <img
                                  src={IconConstants.Minus}
                                  alt=""
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </IconButton>
                            <Typography
                              sx={{
                                color: "#181818",
                                fontSize: "4.267vw",
                                fontWeight: 500,
                              }}
                            >
                              {item.quantity}
                            </Typography>
                            <IconButton
                              aria-label="add to cart"
                              onClick={() => handleAddToCart(item)}
                              size="small"
                              sx={{ p: 0 }}
                            >
                              <Box
                                sx={{
                                  width: "6.933vw",
                                  height: "6.933vw",
                                }}
                              >
                                <img
                                  src={IconConstants.Plus}
                                  alt=""
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "2.308vw",
                          fontWeight: 500,
                          color: "#989898",
                          mt: "2%",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} key={item.id} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    boxShadow:
                      "12.48px 27.04px 52px 0px rgba(90, 108, 234, 0.07)",
                    borderRadius: "7.28px",
                    minHeight: "27.467vw",
                    padding: "5% 2%",
                     backgroundColor: "#FFFFFF"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ width: "19%" }}>
                      <Box sx={{ height: "16.533vw", width: "16.533vw" }}>
                        <img
                          src={item.imageUrl}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "7.28px",
                          }}
                        />
                      </Box>
                    </Box>

                    <Box sx={{ width: "77.5%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            // width: "65%",
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "1.5vw",
                          }}
                        >
                         <Typography
                            sx={{ fontSize: "3.333vw", fontWeight: 500, color:"#959595" }}
                          >
                            {item?.name?.split("(")?.[0]}
                          </Typography>
                          {!!item?.name?.split("(")?.[1] ? (
                            <Typography
                              sx={{
                                fontSize: "2.564vw",
                                color: "#989898",
                                fontWeight: 500,
                              }}
                            >
                              {`(${item?.name?.split("(")?.[1]}`}
                            </Typography>
                          ) : (
                            ""
                          )}
                          <Typography
                            sx={{
                              color: "#EBA059",
                              fontSize: "3.333vw",
                              fontWeight: 700,
                            }}
                          >
                            Unavailable at this moment
                          </Typography>
                        </Box>

                        <Box sx={{ width: "10%" }}>
                          <Box sx={{ height: "4.359vw", width: "4.359vw" }}>
                            <img
                              src={
                                "https://ysquare-order-management.s3.ap-south-1.amazonaws.com/Bucket+Biryani/Support+Images/out_of_stock.png"
                              }
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "2.308vw",
                          fontWeight: 500,
                          color: "#989898",
                          mt: "2%",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )
          )}
      </Grid>
    </Box>
  );
};

export default MenuList;
